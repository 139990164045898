import React, {Component, Fragment} from "react";
import key from "weak-key";

class PriceList extends Component {

  renderPrices({duration, price, title}) {
    return (
      <div key={key({duration, price})} className="price-list__price-item">
        {!!title && <span className="price-list__price-title">{title}</span>}
        <span>{duration} min</span>
        <span className="price-list__price-separator"/>
        <span>{price},-€</span>
      </div>
    );
  }

  renderService({title, subtitle, prices}) {
    return (
      <div className="col-xl-4 col-lg-6 col-12 text-left" key={key({title, prices})}>
        <div className="service service__style--2">
          <div className="content">
            <h3 className="title">{title}</h3>
            <p>{subtitle}</p>
            {prices.map(this.renderPrices.bind(this))}
          </div>
        </div>
      </div>
    )
  }

  render() {
    const {priceList: {prices, selfDeveloped, osteopathy}} = this.props;

    return (
      <React.Fragment>
        <div className="row">
          {prices.map(this.renderService.bind(this))}
        </div>
        <div className="row">
          <div className="col-12 text-left">
            <div className="service service__style--2">
              <div className="content">
                <h3 className="title">{selfDeveloped.title}</h3>
                <p>{selfDeveloped.subtitle}</p>
                {selfDeveloped.prices.map(this.renderPrices.bind(this))}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-left">
            <div className="service service__style--2">
              <div className="content">
                <h3 className="title">{osteopathy.title}</h3>
                <h4>{osteopathy.subtitle}</h4>
                <ul>
                  {osteopathy.services.map((service) => (
                    <li key={key({service})}>{service}</li>
                  ))}
                </ul>
                {osteopathy.systems.map((system) => (
                  <Fragment key={key(system)}>
                    <h4>{system.title}</h4>
                    <p>
                      {system.description}
                    </p>
                  </Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default PriceList;
