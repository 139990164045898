import React, {useState} from 'react';
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import ImagesGallery from "../components/galleries/ImagesGallery";
import Pagination from "../components/Pagination";

const PAGE_SIZE = 21;

const GalleryArticles = ({photos}) => {
  const pagesCount = Math.ceil(photos.length / PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(1);
  const pageStartIndex = (currentPage - 1) * PAGE_SIZE;
  const pageEndIndex = pageStartIndex + PAGE_SIZE;
  const photosToShow = photos.slice(pageStartIndex, pageEndIndex);

  const handlePageChange = (selectedPage) => setCurrentPage(selectedPage);

  return (
    <div className="rn-blog-area ptb--120 bg_color--1">
      <div className="container">
        <div className="row align-items-end">
          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
            <div className="section-title text-left">
              <h2>Veröffentlichungen</h2>
            </div>
            <div className="portfolio-btn">
              <a className="rn-btn text-white" href="/photo-gallery">Foto</a>
              <a className="rn-btn text-white" href="/video-gallery">Video</a>
              <a className="rn-btn text-white" href="/certificates-gallery">Zertifikate</a>
              <a className="rn-btn text-white" href="/articles-gallery">Veröffentlichungen</a>
            </div>
          </div>
        </div>
        <div className="row mt--60 mt_sm--40">
          <ImagesGallery column="col-lg-4 col-md-6 col-12" photos={photosToShow}/>
        </div>
      </div>

      <Pagination total={pagesCount} current={currentPage} onPageChange={handlePageChange}/>
    </div>
  );
};

export default GalleryArticles;
