import React, {useState} from "react";
import ReactPlayer from "react-player";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import key from "weak-key";


const getYoutubeVideoIdFromUrl = (url) => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);

  return (match && match[7].length === 11) ? match[7] : false;
};

const getVideoThumbnail = (id) => `https://img.youtube.com/vi/${id}/0.jpg`;

const VideosGallery = ({column, videos}) => {
  const [videoIndex, setVideoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  if (!videos) {
    return null;
  }

  const list = videos.map(getYoutubeVideoIdFromUrl).filter((url) => !!url);

  const renderLightbox = () => {
    if (videos) {
      return list.map((videoId, index) => (
          <ReactPlayer
            key={key({videoId, index})}
            width="100%"
            url={`https://www.youtube.com/watch?v=${videoId}`}/>)
        );
    }

    return list.map((url, index) => (
      <img key={key({url, index})} src={url} />
    ))
  }

  return (
    <React.Fragment>
      {isOpen && (
        <div className="portfolio-gallery_lightbox">
          <button type="button" className="portfolio-gallery_lightbox-close"
                  onClick={() => setIsOpen(false)}/>
          <Carousel showThumbs={false}
                    selectedItem={videoIndex}
                    renderItem={(item, {isSelected}) => <item.type {...item.props} playing={isSelected}/>}>
            {renderLightbox()}
          </Carousel>
        </div>
      )}

      {list.map((item, index) => (
        <div className={`${column}`} key={index}>
          <div className="portfolio-tilthover">
            <div onClick={() => {
              setIsOpen(true);
              setVideoIndex(index)
            }} className="Tilt-inner">
              <div className="portfolio">
                <div className="thumbnail-inner">
                  <div className={`thumbnail`}
                       style={{backgroundImage: `url("${getVideoThumbnail(item)}")`}}/>
                  <div className={`bg-blr-image`}
                       style={{backgroundImage: `url("${getVideoThumbnail(item)}")`}}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}

    </React.Fragment>
  )
}

export default VideosGallery;
