import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import Error404 from "./pages/Error404";

import {BrowserRouter, Switch, Route} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import PhotoGallery from "./pages/PhotoGallery";
import GalleryArticles from "./pages/GalleryArticles";
import GalleryCertificates from "./pages/GalleryCertificates";
import HomePage from "./pages/HomePage";
import VideoGallery from "./pages/VideoGallery";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import {FaInstagram, FaWhatsapp, FaYoutube} from "react-icons/fa";

const data = {
  navigation: [
    {id: 'home', title: 'Home'},
    {id: 'uber-mich', title: 'Über mich'},
    {id: 'dienstleistungen', title: 'Dienstleistungen'},
    {id: 'preisliste', title: 'Preisliste'},
    {id: 'galerie', title: 'Galerie'},
    {id: 'video-galerie', title: 'Video-Galerie'},
    {id: 'kontakt', title: 'Kontakt'},
  ],
  social: [
    {icon: <FaWhatsapp /> , link: 'https://wa.me/4917637610365'},
    {icon: <FaYoutube /> , link: 'https://www.youtube.com/channel/UC0LsSBFUMZujER9N7gRxnsg'},
    {icon: <FaInstagram /> , link: 'https://instagram.com/spiritphysio'}
  ],
  homePage: {
    home: {
      title: 'Hallo, ich heiße Igor Lukaschewitsch',
      textLoop: ['Wellnessberater.', 'Masseur.', 'Osteopath.'],
    },
    about: {
      title: 'Über mich',
      description: `Egal ob Hexenschuss, 
        eingeklemmte Nerven, starke Verspannungen, Beckenschiefstand oder Waden -Oberschenkelschmerz, Krämpfe oder "Tennisarm" - ich kann diese Beschwerden korriegieren oder sogar schmerzfrei machen. Dabei wird der Körper in sein Gleichgewicht gebracht, alles kann sich entspannen. 
        Ich verwende Manuelletherapie, Dorntherapie, Osteopathie, Akupressur und arbeite auch viel mit Meredianen. Ich bringe den Körper im Einklang- mit der Energie und den Geist, das Endspant und bring den Körper ins gleichgewicht so das die Seele Freude hat dort zu leben.
      `,
      tabs: [
        {
          title: 'Kernkompetenzen',
          content: `
            <ul>
              <li>
                <a href="https://www.aswm.eu/">Akademie für Spa und Wellness Massage (ASWM) 
                  <span>international ausgerichteter Bildungsprogramme für Fach- und Führungskräfte</span>
                </a>
              </li>
              <li>Masseur</li>
              <li>Wellnessberater</li>
              <li>Trainer</li>
            </ul>
          `,
        },
        {
          title: 'Zertifikat',
          content: `
            <ul>
              <li>Fitnesstrainer/in-B-Lizenz</li>
              <li>Gesichtsmassage</li>
              <li>Fitness und Bewegung</li>
              <li>Akupressur</li>
              <li>Mobilemassage</li>
              <li>Aromatherapie</li>
            </ul>
          `,
        },
      ],
    },
  },
};

const videos = [
  'https://www.youtube-nocookie.com/watch?v=lVVhSQK9XS0',
  'https://www.youtube-nocookie.com/watch?v=0iffACX0Amo',
  'https://www.youtube-nocookie.com/watch?v=M1fQCFuqYOw',
  'https://www.youtube-nocookie.com/watch?v=uj_gsyfkZ7M',
  'https://www.youtube-nocookie.com/watch?v=nR_P9kKKEz0'
];

const Root = () => {
  const [photos, setPhotos] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    // todo fetch data here
    const fetchPhotoUrls = async () => {
      const request = await fetch('/photo-manifest.json');
      const photosData = await request.json();
      const photoUrls = photosData.map(url => `/assets/gallery/photo/${url}`);

      setPhotos(photoUrls);
    }

    const fetchCertificateUrls = async () => {
      const request = await fetch('/certificates-manifest.json');
      const data = await request.json();
      const certificareUrls = data.map(url => `/assets/gallery/certificates/${url}`);

      setCertificates(certificareUrls);
    }

    const fetchArticleUrls = async () => {
      const request = await fetch('/articles-manifest.json');
      const data = await request.json();
      const articleUrls = data.map(url => `/assets/gallery/articles/${url}`);

      setArticles(articleUrls);
    }
    fetchPhotoUrls();
    fetchCertificateUrls();
    fetchArticleUrls();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className="active-dark">
      <Header navigation={data?.navigation} social={data?.social} />

      <BrowserRouter basename={'/'}>
        <Switch>
          <Route exact path={`${process.env.PUBLIC_URL}/`}>
            <HomePage {...data.homePage} photos={photos} videos={videos} certificates={certificates} articles={articles} />
          </Route>

          <Route exact path={`${process.env.PUBLIC_URL}/photo-gallery`}>
            <PhotoGallery photos={photos} />
          </Route>

          <Route exact path={`${process.env.PUBLIC_URL}/certificates-gallery`}>
            <GalleryCertificates photos={certificates} />
          </Route>

          <Route exact path={`${process.env.PUBLIC_URL}/articles-gallery`}>
            <GalleryArticles photos={articles} />
          </Route>

          <Route exact path={`${process.env.PUBLIC_URL}/video-gallery`}>
            <VideoGallery videos={videos} />
          </Route>

          <Route path={`${process.env.PUBLIC_URL}/404`} component={Error404} />

          <Route component={Error404} />
        </Switch>
      </BrowserRouter>

      <Footer social={data?.social} />
    </div>
  )
}

ReactDOM.render(<Root />, document.getElementById('root'));
serviceWorker.register();
