import React, {Fragment} from "react";
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import key from "weak-key";

const AboutTabs = ({tabs}) => (
  <div>
    {/* Start Tabs Area */}
    <div className="tabs-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <Tabs>
              <TabList className="tab-style--1">
                {tabs?.map(({title}) => (
                  <Fragment key={key({title})}>
                    <Tab>{title}</Tab>
                  </Fragment>
                ))}
              </TabList>

              {tabs?.map(({content}) => (
                <Fragment key={key({content})}>
                  <TabPanel>
                    <div
                      className="single-tab-content"
                      dangerouslySetInnerHTML={{__html: content}}
                    />
                  </TabPanel>
                </Fragment>
              ))}
            </Tabs>
          </div>
        </div>
      </div>
    </div>
    {/* End Tabs Area */}
  </div>
)


export default AboutTabs;
