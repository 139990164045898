import React from 'react';
import {FaAngleLeft, FaAngleRight} from "react-icons/fa";
import classNames from "classnames";
import key from "weak-key";

export default function Pagination({total, current, onPageChange}) {
  const renderItems = () => Array.from({length: total}).map((_, index) => {
    const pageIndex = index + 1;

    return (
      <li key={key({index})}
          className={classNames(pageIndex === current && 'active')}
          onClick={() => onPageChange(pageIndex)}
      >
        {pageIndex}
      </li>
    )
  });

  const renderPrevButton = () => {
    if (current === 1) {
      return null;
    }

    return <li onClick={() => onPageChange(current - 1)}><FaAngleLeft/></li>;
  };

  const renderNextButton = () => {
    if (current === total) {
      return null;
    }

    return <li onClick={() => onPageChange(current + 1)}><FaAngleRight/></li>;
  };

  if (total === 1) {
    return null;
  }

  return (
    <div className="rn-pagination text-center">
      <ul className="page-list">
        {renderPrevButton()}
        {renderItems()}
        {renderNextButton()}
      </ul>
    </div>
  )
}
