import React from 'react';
import ScrollToTopButton from "./ScrollToTopButton";

const Footer = ({social}) => {
    return (
        <div className="footer-style-2 ptb--30 bg_image bg_image--1" data-black-overlay="6">
            <div className="wrapper plr--50 plr_sm--20">
                <div className="row align-items-center justify-content-between">
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="text">
                            <p>Copyright © {new Date().getFullYear()} Wellness Massage Praxis</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                        <div className="inner text-lg-right text-center mt_md--20 mt_sm--20">
                            <div className="inner text-center">
                                <ul className="social-share rn-lg-size d-flex justify-content-center liststyle">
                                    {social?.map((val , i) => (
                                      <li key={i}><a href={`${val.link}`}>{val.icon}</a></li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ScrollToTopButton />
        </div>
    )
}
export default Footer;
