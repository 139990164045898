import React, {Component} from "react";
import key from "weak-key";

class ServiceThree extends Component {

  renderService({title, description, icon}) {
    if (!description) {
      return null;
    }

    const {itemClass} = this.props;

    return (
      <div className={itemClass} key={key({title, description})}>
        <div className="service service__style--2">
          {!!icon && (
            <div className="icon">
              {icon}
            </div>
          )}
          <div className="content">
            <h3 className="title">{title}</h3>
            <p>{description}</p>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const {services} = this.props;

    return (
      <React.Fragment>
        <div className="row">
          {services.map(this.renderService.bind(this))}
        </div>
      </React.Fragment>
    )
  }
}

export default ServiceThree;
