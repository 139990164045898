import React, {useState} from "react";
import ReactPlayer from "react-player";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import key from "weak-key";

const ImagesGallery = ({column, photos}) => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  if (!photos) {
    return null;
  }

  const renderLightbox = () => photos.map((url, index) => (
    <img key={key({url, index})} src={url} />
  ));

  return (
    <React.Fragment>
      {isOpen && (
        <div className="portfolio-gallery_lightbox">
          <button type="button" className="portfolio-gallery_lightbox-close"
                  onClick={() => setIsOpen(false)}/>
          <Carousel showThumbs={false}
                    selectedItem={photoIndex}
                    renderItem={(item, {isSelected}) => <item.type {...item.props} playing={isSelected}/>}>
            {renderLightbox()}
          </Carousel>
        </div>
      )}

      {photos.map((item, index) => (
        <div className={`${column}`} key={index}>
          <div className="portfolio-tilthover">
            <div onClick={() => {
              setIsOpen(true);
              setPhotoIndex(index)
            }} className="Tilt-inner">
              <div className="portfolio">
                <div className="thumbnail-inner">
                  <div className={`thumbnail`} style={{backgroundImage: `url("${item}")`}}/>
                  <div className={`bg-blr-image`} style={{backgroundImage: `url("${item}")`}}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}

    </React.Fragment>
  )
}

export default ImagesGallery;
