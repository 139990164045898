import ScrollToTop from "react-scroll-up";
import {FiChevronUp} from "react-icons/fi";
import React from "react";

const ScrollToTopButton = () => (
  <div className="backto-top">
    <ScrollToTop showUnder={160}>
      <FiChevronUp/>
    </ScrollToTop>
  </div>
);

export default ScrollToTopButton;
