import React, {useEffect} from "react";
import {FiX, FiMenu} from "react-icons/fi";
import Scrollspy from 'react-scrollspy'

const Header = ({navigation, social}) => {
  const openMenuHandler = () => {
    document.querySelector('.header-wrapper').classList.toggle('menu-open')
  }

  const closeMenuHandler = () => {
    document.querySelector('.header-wrapper').classList.remove('menu-open')
  }

  useEffect(() => {
    window.addEventListener('scroll', function () {
      const value = window.scrollY;
      if (value > 100) {
        document.querySelector('.header--fixed').classList.add('sticky')
      } else {
        document.querySelector('.header--fixed').classList.remove('sticky')
      }
    });

    const elements = document.querySelectorAll('.has-droupdown > a');
    for (let i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement.querySelector('.submenu').classList.toggle("active");
          this.classList.toggle("open");
        }
      }
    }
  }, []);

  return (
    <header className="header-area header-style-two header--fixed color-black">
      <div className="header-wrapper">
        <div className="header-left d-flex align-items-center">
          <div className="logo">
            <a href="/">
              <img src="/assets/images/logo/logo-dark.svg" alt="Wellness massage praxis"/>
            </a>
          </div>
          <nav className="mainmenunav d-lg-block ml--50">
            <Scrollspy className="mainmenu" items={navigation?.map(({id}) => (id))} currentClassName="is-current"
                       offset={-200}>
              {navigation?.map(({id, title}) => (
                <li key={id}><a onClick={closeMenuHandler} href={`/#${id}`}>{title}</a></li>
              ))}
            </Scrollspy>
          </nav>
        </div>
        <div className="header-right">
          <div className="social-share-inner">
            <ul className="social-share social-style--2 color-black d-flex justify-content-start liststyle">
              {social?.map((val, i) => (
                <li key={i}><a href={`${val.link}`}>{val.icon}</a></li>
              ))}
            </ul>
          </div>
          {/* Start Humberger Menu  */}
          <div className="humberger-menu d-block d-lg-none pl--20">
            <span onClick={openMenuHandler} className="menutrigger text-white"><FiMenu/></span>
          </div>
          {/* End Humberger Menu  */}
          <div className="close-menu d-block d-lg-none">
            <span onClick={closeMenuHandler} className="closeTrigger"><FiX/></span>
          </div>
        </div>
      </div>
    </header>
  )
}
export default Header;
