import React, {Component} from "react";

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rnName: '',
      rnEmail: '',
      rnMessage: '',
    };
  }

  render() {
    return (
      <div className="contact-form--1">
        <div className="container">
          <div className="row row--35 align-items-start">
            <div className="col-lg-6 order-2 order-lg-1">
              <div className="section-title text-left mb--50">
                <h2 className="title">{this.props.contactTitle}</h2>
                <p className="description">
                  Oder rufen Sie uns an.: <a href="tel:+4917637610365">+4917637610365</a>
                </p>
              </div>
              <div className="form-wrapper">
                <form>
                  <label htmlFor="item01">
                    <input
                      type="text"
                      name="name"
                      id="item01"
                      value={this.state.rnName}
                      onChange={(e) => {
                        this.setState({rnName: e.target.value});
                      }}
                      placeholder="Name *"
                    />
                  </label>

                  <label htmlFor="item02">
                    <input
                      type="text"
                      name="email"
                      id="item02"
                      value={this.state.rnEmail}
                      onChange={(e) => {
                        this.setState({rnEmail: e.target.value});
                      }}
                      placeholder="E-Mail-Adresse *"
                    />
                  </label>
                  <label htmlFor="item04">
                                        <textarea
                                          type="text"
                                          id="item04"
                                          name="message"
                                          value={this.state.rnMessage}
                                          onChange={(e) => {
                                            this.setState({rnMessage: e.target.value});
                                          }}
                                          placeholder="Nachricht"
                                        />
                  </label>
                  <a
                    className="rn-button-style--2 btn-solid" type="submit" value="submit"
                    name="submit" id="mc-embedded-subscribe">
                      Formular senden
                  </a>
                </form>
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <div className="thumbnail mb_md--30 mb_sm--30">
                <img src={`${this.props.contactImages}`} alt="trydo"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ContactForm;
