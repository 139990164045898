import React from 'react';
import TextLoop from "react-text-loop";
import TabTwo from "../components/AboutTabs";
import ContactForm from "../components/ContactForm";
import ServiceList from "../components/Services";
import ImagesGallery from "../components/galleries/ImagesGallery";
import {IoIosBody} from "react-icons/io";
import {GiFootprint, GiLotus, GiSplitBody} from "react-icons/gi";
import {MdHearing} from "react-icons/md";
import {FaBone} from "react-icons/fa";
import PriceList from "../components/PriceList";
import VideosGallery from "../components/galleries/VideosGallery";
import key from "weak-key";
import AboutTabs from "../components/AboutTabs";

const servicesList = [
  {
    icon: <IoIosBody/>,
    title: 'Ganzkörpermassage',
    description: 'Die klassische Ganzkörpermassage hilft, die Gesundheit und das Wohlbefinden zu verbessern und zu erhalten. Sie dient zur Entspannung sowie Verbesserung der Durchblutung von Haut und Muskulatur. Die Sinneszellen der Haut werden durch Berührungen verwöhnt. Die Muskulatur hat durch die Massage die Möglichkeit sich zu entspannen. Über den Körper erreicht die Entspannung die emotionale Ebene.\n' +
      'Die integrierten Kopf-, Nacken-, und Fußmassagen verwöhnen zusätzlich.'
  },
  {
    icon: <GiLotus/>,
    title: 'Aromaölmassage',
    description: 'Die heilenden Kräfte der Öle, die bereits seit Jahrhunderten bekannt sind entfalten sich über die Haut und den Geruchssinn. Das breite Sortiment an unseren hochwertigen Aromaölmischungen à la Loipersdorf ist auf die speziellen Bedürfnisse Ihres Körpers abgestimmt. Diese alternative Heilmethode stärkt Ihr Immunsystem, nährt Ihre Haut und bringt Sie in Harmonie.'
  },
  {
    icon: <GiFootprint/>,
    title: 'Fußreflexzonenmassage',
    description: 'Schon vor Jahrtausenden kannten Kulturvölker (Indianer) die Zusammenhänge und Möglichkeiten der organfernen Behandlung. Um die Jahrhundertwende wurde die Fußreflexzonenmassage durch den amerikanischen Arzt Dr. William Fitzgerald systematisch in eine moderne Form gebracht. In den 30er-Jahren kam die Fußreflexzonenmassage schließlich aus den USA nach Europa. Sie ist eine reflektorische Behandlungsart (Reizbehandlung) und bewirkt eine lokale und intensive Durchblutung und somit eine Verbesserung sämtlicher Organ- und Gewebefunktionen. Bei der Fußreflexzonenmassage wird hauptsächlich mit Daumendruck abwechselnd an beiden Füßen gearbeitet. Anwendungsgebiete: Verdauungsprobleme, Verspannungen, chronische Erkrankungen, vegetative Beschwerden.'
  },
  {
    icon: <MdHearing/>,
    title: 'Ohrkerzenmassage (mit Ohr und Kopfmassage)',
    description: 'Die Ohrkerzenbehandlung soll bereits vor hunderten von Jahren von asiatischen Naturvölkern durchgeführt worden sein.Die klassischen Anwendungsbereiche der Ohrenkerzenbehandlung sind Nervosität, Schlaflosigkeit, Kopfschmerzen, Konzentrationsstörungen, Erkältungen, Schnupfen, beeinträchtigtes Hörvermögen, Tinnitus sowie Stresssymptome. Nach einer entspannenden Kopfmassage werden Sie die mit der Magie des Feuers verwöhnt.\n' +
      'Durch die Unterdruck- und Wärmeentwicklung (Kamineffekt) die beim Abbrennen der Ohrkerze entsteht, empfindet man ein intensives Gefühl von angenehmer Wärme und einem Druckausgleich im Ohr-, Stirn- und Nebenhöhlenbereich. Viele sprechen auch von einer wohltuenden Trommelfellmassage.\n' +
      'Nach der Behandlung wird noch eine Ruhezeit empfohlen.'
  },
  {
    icon: <GiSplitBody/>,
    title: 'Chiromassage',
    description: 'Die Chiromassage ist verbunden mit der Struktur, Ausrichtung, Haltung und Bewegung des Körpers. Durch eine tiefgreifende Handmassage werden die Muskeln gedehnt, gestärkt und in Gleichgewicht gebracht. Dabei wird die Wirbelsäule gestreckt und nach oben orientiert, während Becken, Beine und Füße so ausgerichtet werden, dass sie in korrekter Stellung aufliegen können. Diese Massage-Technik ist besonders hilfreich für die Verbesserung der Körperhaltung, da man die durch eine schlechte Haltung entstandenen Schmerzzonen lokalisieren kann. Durch den mechanischen Druck der Hände, der an die verschiedenen Körperorgane und -gewebe übertragen wird, werden auch Atmung, Kreislauf und Verdauung verbessert.Die Chiromassage hat schmerzlindernde Effekte und, durch das Empfinden übertragener Energie, auch einen positiven Einfluss auf Stimmung, Müdigkeit und Psyche. Für die massierte Person stellt die Chiromassage auch eine Form dar, durch den Handkontakt des Masseurs den eigenen Körper, aber auch das physische und emotional Ich wiederzuentdecken, was hilft Verspannungen, Blockierungen und Unausgewogenheit bewusst zu machen und zu lösen.'
  },
  {
    icon: <FaBone/>,
    title: 'Osteopathie',
    description: 'Die Osteopathie ist eine ganzheitliche Sichtweise der Medizin, die der Diagnose und Behandlung von Funktionsstörungen dient. Die osteopathiesche Medizin beruht auf den philosophischen Prinzipen und verbindet diese mit den allgemeinen anerkannten Standards der Medizin. Die Osteopathie beinhaltet eine umfassende manuelle Untersuchung, Therapie und Prävention von Funktionsstörungen im Bewegungsapparat, den Inneren Organen und dem Nervensystem.\n' +
      'Ist eine Struktur in ihrer Funktion eingeschränkt, so ist auch deren Bewegung eingeschränkt. Dies wird nicht nur deutlich bei Gelenkbewegungen, sondern zum Beispiel auch bei der Atmung oder der Darmperistaltik. Diese Einschränkungen erkennt und behandelt der Osteopath, um der gestörten Struktur seine optimaleBeweglichkeit und somit seine Funktion wiederzugeben. Es ist also nicht das Ziel der Osteopathie nach einer Krankheit oder einem Symptom zu suchen, sondern nach der freien Beweglichkeit und Gesundheit.Gesundheit ist dabei kein Ziel, sondern ein kontinuierlicher Prozess, in dem der Organismus versucht sich so gut wie möglich an ständige äußere und innere Einflüsse anzupassen, die ihn aus dem Gleichgewicht bringen. Kann sich der Körper nicht im Gleichgewicht halten, entstehen erst Funktionsstörungen, später Erkrankungen. Die Fähigkeit des Körpers zur Selbstregulation verdanken wir dabei den Selbstheilungskräften, die auf vielfältiger Weise im Körper anwesend sind. Das Hauptanliegen der Osteopathie ist die Unterstützung dieser körpereigenen Selbstregulation, in dem er in allen Körpergeweben für eine optimale Bewegungsfreiheit sorgt.'
  },
]

const priceList = {
  prices: [
    {
      title: 'Ganzkörpermassage',
      prices: [
        {
          duration: 30,
          price: 30,
        },
        {
          duration: 60,
          price: 50,
        },
      ]
    },
    {
      title: 'Aromaölmassage',
      prices: [
        {
          duration: 30,
          price: 30,
        },
        {
          duration: 60,
          price: 50,
        },
      ]
    },
    {
      title: 'Fußreflexzonenmassage',
      prices: [
        {
          duration: 30,
          price: 28,
        },
        {
          duration: 45,
          price: 40,
        },
      ]
    },
    {
      title: 'Ohrkerzenmassage (mit Ohr und Kopfmassage)',
      prices: [
        {
          duration: 30,
          price: 28,
        },
        {
          duration: 45,
          price: 35,
        },
      ]
    },
    {
      title: 'Chiromassage',
      prices: [
        {
          duration: 60,
          price: 50,
        },
        {
          duration: 90,
          price: 80,
        },
      ]
    },
    {
      title: 'Schröpfkopfmassage',
      prices: [
        {
          duration: 30,
          price: 30,
        },
        {
          duration: 60,
          price: 60,
        },
      ]
    },
    {
      title: 'Hot Stone Massage',
      prices: [
        {
          duration: 60,
          price: 60,
        },
        {
          duration: 90,
          price: 85,
        },
        {
          duration: 120,
          price: 110,
        },
      ]
    },
    {
      title: 'Lomi Lomi Nui',
      prices: [
        {
          duration: 60,
          price: 60,
        },
        {
          duration: 90,
          price: 85,
        },
      ]
    },
    {
      title: 'Honigmassage',
      prices: [
        {
          duration: 30,
          price: 30,
        },
        {
          duration: 45,
          price: 40,
        },
      ]
    },
    {
      title: 'Antizellulitmassage',
      prices: [
        {
          duration: 30,
          price: 30,
        },
        {
          duration: 60,
          price: 60,
        },
      ]
    },
    {
      title: 'Entschlackungsmassage (Lymphdrainage)',
      prices: [
        {
          duration: 60,
          price: 60,
        },
        {
          duration: 90,
          price: 90,
        },
      ]
    },
    {
      title: 'Thai-Yoga Massage',
      prices: [
        {
          duration: 60,
          price: 60,
        },
        {
          duration: 90,
          price: 90,
        },
        {
          duration: 120,
          price: 110,
        },
      ]
    },
    {
      title: 'Shiatsumassage',
      prices: [
        {
          duration: 60,
          price: 60,
        },
        {
          duration: 90,
          price: 90,
        },
        {
          duration: 120,
          price: 110,
        },
      ]
    },
  ],
  selfDeveloped: {
    title: 'Selbst entwickelte Massagetechnik "Wiederherstellung des Körpers"',
    prices: [
      {
        title: 'Erstuntersuchung',
        duration: 30,
        price: 30,
      },
      {
        title: 'Folgendebehandlung',
        duration: 30,
        price: 35,
      },
      {
        title: 'FFolgedebehandlung',
        duration: 60,
        price: 65,
      },
    ],
  },
  osteopathy: {
    title: 'Osteopathie',
    subtitle: 'Teilt sich in drei teilen Bewegungsapparat, inneren Organen und Cranio-Sacralen System.',
    services: [
      'Osteopathie Erstuntersuchung und Behandlung Erwachsene',
      'Osteopathie Erstuntersuchung und Behandlung Kinder',
      'Die Osteopathie-Sitzungen für Erwachsene',
      'Die Kinderosteopathie-Sitzungen',
    ],
    systems: [
      {
        title: 'Das Parietale System',
        description: 'Bewegungsapparat mit Wirbelsäule, Gelenken, Muskeln, Sehnen und Bändern.',
      },
      {
        title: 'Das Viszerale System',
        description: 'Alle inneren Organe des Brust-, Bauch- und Beckenraumes.',
      },
      {
        title: 'Das Kraniale System',
        description: 'Schädel und Wirbelsäule samt Inhalt, also Gehirn, Nervensystem, Membrane und Gehirnflüssigkeit.',
      },
    ],
  },
};

const HomePage = ({home, about, photos, videos, certificates, articles}) => (
  <>
    {/* Start Slider Area   */}
    <div id="home" className="fix">
      <div className="slider-wrapper">
        {/* Start Single Slide */}
        <div
          className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--25">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-left">
                  <span>WELLNESS MASSAGE PRAXIS</span>
                  <h1 className="title">{home?.title}<br/>
                    <TextLoop>
                      {home?.textLoop?.map((text) => <span key={key({text})}> {text}</span>)}
                    </TextLoop>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Single Slide */}
      </div>
    </div>
    {/* End Slider Area   */}

    {/* Start About Area */}
    <div id="uber-mich" className="fix">
      <div className="about-area ptb--120  bg_color--5">
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-5">
                <div className="thumbnail ">
                  <img className="w-100" src="/assets/images/bg/igor.png" alt="About Images"/>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">{about?.title}</h2>
                    <p className="description">{about?.description}</p>
                  </div>
                  <div className="row mt--30">
                    <AboutTabs tabs={about?.tabs}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* End About Area */}

    {/* Start Service Area  */}
    <div id="dienstleistungen" className="fix">
      <div className="service-area creative-service-wrapper ptb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                <h2 className="title">Meine Dienstleistungen</h2>
              </div>
            </div>
          </div>
          <div className="row creative-service">
            <div className="col-lg-12">
              <ServiceList services={servicesList} itemClass="col-12 text-left"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* End Service Area  */}

    {/* Start Price Area  */}
    <div id="preisliste" className="fix">
      <div className="service-area creative-service-wrapper ptb--120 bg_color--5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                <h2 className="title">Preisliste</h2>
              </div>
            </div>
          </div>
          <div className="row creative-service">
            <div className="col-lg-12">
              <PriceList priceList={priceList}/>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* End Price Area  */}

    {/* Start Photo Gallery Area */}
    <div id="galerie" className="fix">
      <div className="rn-blog-area ptb--120 bg_color--1">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="section-title text-center">
                <h2>Galerie</h2>
              </div>
            </div>
          </div>
          <div className="row mt--60 mt_sm--40">
            <ImagesGallery column="col-lg-4 col-md-6 col-12" photos={photos?.slice(0, 6)}/>
          </div>
          <a className="rn-button-style--2 btn-solid show-all-btn" href="/photo-gallery">
            Zeige alles
          </a>
        </div>
      </div>
    </div>
    {/* End Photo Gallery Area */}

    <div id="galerie" className="fix">
      <div className="rn-blog-area ptb--120 bg_color--5">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="section-title text-center">
                <h2>Zertifikate</h2>
              </div>
            </div>
          </div>
          <div className="row mt--60 mt_sm--40">
            <ImagesGallery column="col-lg-4 col-md-6 col-12" photos={certificates?.slice(0, 3)}/>
          </div>
          <a className="rn-button-style--2 btn-solid show-all-btn" href="/certificates-gallery">
            Zeige alles
          </a>
        </div>
      </div>
    </div>

    <div id="articles" className="fix">
      <div className="rn-blog-area ptb--120 bg_color--1">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="section-title text-center">
                <h2>Veröffentlichungen</h2>
              </div>
            </div>
          </div>
          <div className="row mt--60 mt_sm--40">
            <ImagesGallery column="col-lg-4 col-md-6 col-12" photos={articles?.slice(0, 3)}/>
          </div>
          <a className="rn-button-style--2 btn-solid show-all-btn" href="/articles-gallery">
            Zeige alles
          </a>
        </div>
      </div>
    </div>

    {/* Start Video Gallery Area */}
    <div id="video-galerie" className="fix">
      <div className="rn-blog-area ptb--120 bg_color--5 mb-dec--30">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="section-title text-center">
                <h2>Video-Galerie</h2>
              </div>
            </div>
          </div>
          <div className="row mt--60 mt_sm--40">
            <VideosGallery column="col-lg-4 col-md-6 col-12" videos={videos?.slice(0, 3)}/>
          </div>
          <a className="rn-button-style--2 btn-solid show-all-btn" href="/video-gallery">
            Zeige alles
          </a>
        </div>
      </div>
    </div>
    {/* End Video Gallery Area */}

    {/* Start Contact Area */}
    <div id="kontakt" className="fix">
      <div className="rn-contact-area ptb--120 bg_color--1">
        <ContactForm contactImages="/assets/images/bg/sitting-buddah.png"
                     contactTitle="Nutzen Sie unser Kontaktformular"/>
      </div>
    </div>
    {/* End Contact Area */}
  </>
);

export default HomePage;
